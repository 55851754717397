.FormLabel {
    color: #212121;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma;
}

.checkIcon {
    font-size: large;
    margin-right: 1%;
}

.addUpdateIcon {
    cursor: pointer;
}

.addUpdateInputBox {
    width: 80%;
    margin-top: 20px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: white;
    font-size: 20px;
}

.updatesListStyle {
    display: flex;
    justify-content: center;
}

.updatesPageTitle {
    color: #1b2d55;
    font-weight: 600;
    font-size: x-large;
    padding: 0;
    margin: 0;
}

.updatesPageSubTitle {
    color: #959485;
    font-size: smaller;
}

.listItemStyle {
    margin-block: 10px;
    border: 1px solid #dedfe1;
    border-radius: 10px;
}

.fixedlistItemStyle {
    margin-block: 10px;
    background-color: #eaf6ff;
    border: 1px solid #bbdefb;
    border-radius: 10px;
}

.updatesContainer {
    padding: 30px;
    background-color: white;
}

.secondaryAction {
    display: none;
}
.backButton {
    background-color: #636678;
    width: 7%;
    height: 2rem;
    color: white;
    font-size: 0.9rem;
    border-radius: 10px;
    margin-bottom: 2%;
}
.addIconStyle {
    margin-right: 3%;
    color: white;
}
